<template>
  <div>
    <v-dialog
      v-model="work_Proof"
      persistent
      :width="userObject.forms_details.length == 1 ? '480px' : '520px'"
    >
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0" height="60px"
          ><v-toolbar-title class="white--text" style="font-size: medium"
            >Proof of Work <br />
            {{ this.userObject.user_name }}</v-toolbar-title
          >
          <v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n2"
            >mdi-close</v-icon
          >
        </v-toolbar>

        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <v-carousel
            v-model="modelfree"
            hide-delimiters
            :show-arrows="false"
            v-if="this.userObject.forms_details.length == 1"
          >
            <v-carousel-item v-for="formss in images" :key="formss">
              <v-card :formss="formss" flat v-if="formss.is_free_text == true">
                <v-text-field
                  label="Working Type"
                  v-model="formss.working_type"
                  dense
                  readonly
                  outlined
                  class="mt-4 mr-4 ml-2"
                ></v-text-field>
                <v-textarea
                  label="Work Notes"
                  v-model="formss.working_comments"
                  dense
                  readonly
                  outlined
                  class="mt-n2 mr-4 ml-2"
                ></v-textarea>
                <div
                  style="border: 1px solid gray; width: fit-content"
                  class="ml-2"
                  v-if="formss.working_field_keys == null"
                >
                  <v-img height="145" width="410"
                    ><v-container
                      class="fill-height d-flex justify-center"
                      fluid
                    >
                      <span>No - Image</span>
                    </v-container>
                  </v-img>
                </div>
                <div v-if="formss.working_field_keys != null">
                  <v-card max-width="520" class="ml-2" flat>
                    <v-carousel
                      v-model="Singlesingleimage"
                      height="200px"
                      hide-delimiters
                      :show-arrows="false"
                      v-if="formss.working_field_keys.length == 1"
                    >
                      <v-carousel-item
                        v-for="img in formss.working_field_keys"
                        :key="img"
                        class="mr-4"
                        :src="img"
                      >
                      </v-carousel-item>
                    </v-carousel>
                    <v-carousel
                      v-model="Singlemultipleimage"
                      height="200px"
                      hide-delimiters
                      v-else
                    >
                      <v-carousel-item
                        v-for="img in formss.working_field_keys"
                        :key="img"
                        :src="img"
                        class="ml-16 mr-16"
                      >
                      </v-carousel-item>
                    </v-carousel>
                  </v-card>
                </div>
              </v-card>
              <v-card :formss="formss" flat v-else>
                <v-text-field
                  label="Working Type"
                  v-model="formss.working_type"
                  dense
                  readonly
                  outlined
                  class="mt-4 mr-4 ml-4 mr-14"
                ></v-text-field>
              
                <v-card flat class="overflow-auto" height="200px">
                 <v-row no-gutters>
                  <v-col
                    v-for="(index, sdx) in formss.form_template"
                    :key="sdx"
                    :cols="sdx === 0 ? '12' : '12 '"
                  >
                    <TextField
                      :formActionType="formActionType"
                      v-if="
                        [
                          'TEXTFIELD',
                          'EMAIL',
                          'DATE',
                          'SINGLE_CHOICE',
                          'DROPDOWN',
                          'TIME',
                          'MOBILENUMBER',
                          'NAME',
                          'STRING',
                          'NUMBER',
                        ].indexOf(index.type.toUpperCase()) > -1
                      "
                      :index="index"
                    />
                    <CheckboxField
                      class="mt-n4"
                      v-if="index.type.toUpperCase() == 'CHECKBOX'"
                      :index="index"
                      :formActionType="formActionType"
                    />
                    <TextAreaField
                      v-if="index.type.toUpperCase() == 'PARAGRAPH'"
                      :index="index"
                      :formActionType="formActionType"
                    />
                    <RatingFile
                      class="mt-n4"
                      v-if="index.type.toUpperCase() == 'RATING'"
                      :index="index"
                      :formActionType="formActionType"
                    />
                    <DividerComp
                      v-if="index.type.toUpperCase() == 'DIVIDER'"
                      :index="index"
                      :formActionType="formActionType"
                    />
                    <FlipSwitch
                      v-if="index.type.toUpperCase() == 'FLIPSWITCH'"
                      :index="index"
                      class="mt-n4"
                      :formActionType="formActionType"
                    />
                  </v-col>
                </v-row>
                
                </v-card>
               
                <div
                  style="border: 1px solid gray; width: fit-content"
                  class="ml-2"
                  v-if="formss.working_field_keys == null"
                >
                  <v-img height="145" width="375"
                    ><v-container
                      class="fill-height d-flex justify-center"
                      fluid
                    >
                      <span>No - Image</span>
                    </v-container>
                  </v-img>
                </div>
                <div v-if="formss.working_field_keys != null">
                  <v-card max-width="520" class="ml-2" flat>
                    <v-carousel
                      v-model="Singlesingleimage"
                      height="200px"
                      hide-delimiters
                      :show-arrows="false"
                      v-if="formss.working_field_keys.length == 1"
                    >
                      <v-carousel-item
                        v-for="img in formss.working_field_keys"
                        :key="img"
                        class="mr-8"
                        :src="img"
                      >
                      </v-carousel-item>
                    </v-carousel>
                    <v-carousel
                      v-model="Singlemultipleimage"
                      height="200px"
                      hide-delimiters
                      v-else
                    >
                      <v-carousel-item
                        v-for="img in formss.working_field_keys"
                        :key="img"
                        :src="img"
                        class="ml-16 mr-16"
                      >
                      </v-carousel-item>
                    </v-carousel>
                  </v-card>
                </div>
              </v-card>
            </v-carousel-item>
          </v-carousel>
          <v-carousel height="100%" v-model="modelfree" hide-delimiters v-else>
            <v-carousel-item v-for="formss in images" :key="formss">
              <v-card
                :formss="formss"
                width="300"
                style="margin-left: 100px"
                flat
                v-if="formss.is_free_text == true"
              >
                <v-text-field
                  label="Working Type"
                  v-model="formss.working_type"
                  dense
                  readonly
                  outlined
                  class="mt-4 mr-4 ml-2"
                ></v-text-field>
                <v-textarea
                  label="Work Notes"
                  v-model="formss.working_comments"
                  dense
                  readonly
                  outlined
                  class="mt-n2 mr-4 ml-2"
                ></v-textarea>
                <div
                  style="border: 1px solid gray; width: fit-content"
                  class="ml-2"
                  v-if="formss.working_field_keys == null"
                >
                  <v-img height="145" width="275"
                    ><v-container
                      class="fill-height d-flex justify-center"
                      fluid
                    >
                      <span>No - Image</span>
                    </v-container>
                  </v-img>
                </div>
                <div v-else-if="formss.working_field_keys.length > 0">
                  <v-card max-width="520" class="ml-2" flat>
                    <v-carousel
                      v-model="twosingleimage"
                      height="200px"
                      width="420px"
                      hide-delimiters
                      :show-arrows="false"
                      v-if="formss.working_field_keys.length == 1"
                    >
                      <v-carousel-item
                        v-for="img in formss.working_field_keys"
                        :key="img"
                        class="mr-3"
                        :src="img"
                      >
                      </v-carousel-item>
                    </v-carousel>
                    <v-carousel
                      v-model="twomultipleimage"
                      height="200px"
                      width="100px"
                      hide-delimiters
                      v-else
                    >
                      <v-carousel-item
                        v-for="img in formss.working_field_keys"
                        :key="img"
                        :src="img"
                        class="mr-3"
                      >
                      </v-carousel-item>
                    </v-carousel>
                  </v-card>
                </div>
              </v-card>
              <v-card :formss="formss" flat v-else class="ml-2 mr-2">
                <v-card-text class="ml-5">
                  <v-text-field
                    label="Working Type"
                    v-model="formss.working_type"
                    dense
                    readonly
                    outlined
                    class="mt-4 mr-14 ml-4"
                  ></v-text-field>
                  <v-row no-gutters>
                    <v-col
                      v-for="(index, sdx) in formss.form_template"
                      :key="sdx"
                      :cols="sdx === 0 ? '12' : '12 mt-n2'"
                      sm="12"
                      xs="12"
                      md="12"
                    >
                      <TextField
                        :formActionType="formActionType"
                        v-if="
                          [
                            'TEXTFIELD',
                            'EMAIL',
                            'DATE',
                            'SINGLE_CHOICE',
                            'DROPDOWN',
                            'TIME',
                            'MOBILENUMBER',
                            'NAME',
                            'STRING',
                            'NUMBER',
                          ].indexOf(index.type.toUpperCase()) > -1
                        "
                        :index="index"
                      />
                      <CheckboxField
                        class="mt-n4"
                        v-if="index.type.toUpperCase() == 'CHECKBOX'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <TextAreaField
                        v-if="index.type.toUpperCase() == 'PARAGRAPH'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <RatingFile
                        class="mt-n4"
                        v-if="index.type.toUpperCase() == 'RATING'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <DividerComp
                        v-if="index.type.toUpperCase() == 'DIVIDER'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <FlipSwitch
                        v-if="index.type.toUpperCase() == 'FLIPSWITCH'"
                        :index="index"
                        class="mt-n4"
                        :formActionType="formActionType"
                      />
                    </v-col>
                  </v-row>
                  <div
                    style="border: 1px solid gray; width: fit-content"
                    class="ml-4"
                    v-if="formss.working_field_keys == null"
                  >
                    <v-img height="145" width="355"
                      ><v-container
                        class="fill-height d-flex justify-center"
                        fluid
                      >
                        <span>No - Image</span>
                      </v-container>
                    </v-img>
                  </div>
                  <div v-if="formss.working_field_keys != null">
                    <v-card max-width="520" class="" flat>
                      <v-carousel
                        v-model="Singlesingleimage"
                        height="200px"
                        hide-delimiters
                        :show-arrows="false"
                        v-if="formss.working_field_keys.length == 1"
                      >
                        <v-carousel-item
                          v-for="img in formss.working_field_keys"
                          :key="img"
                          class="mr-14 ml-4"
                          :src="img"
                        >
                        </v-carousel-item>
                      </v-carousel>
                      <v-carousel
                        v-model="Singlemultipleimage"
                        height="200px"
                        hide-delimiters
                        style="max-width: 380px"
                        v-else
                      >
                        <v-carousel-item
                          v-for="img in formss.working_field_keys"
                          :key="img"
                          :src="img"
                          class="ml-4 mr-1"
                        >
                        </v-carousel-item>
                      </v-carousel>
                    </v-card>
                  </div>
                </v-card-text>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import TextField from "@/components/FormDesigner/FormFields/TextField.vue";
// import NumberField from "@/components/FormDesigner/FormFields/NumberField.vue";
import CheckboxField from "@/components/FormDesigner/FormFields/CheckboxField.vue";
import TextAreaField from "@/components/FormDesigner/FormFields/TextAreaField.vue";
// import DateField from "@/components/FormDesigner/FormFields/DateField.vue";
// import DropdowmField from "@/components/FormDesigner/FormFields/DropdowmField.vue";
// import TimePicker from "@/components/FormDesigner/FormFields/TimePicker.vue";
import RatingFile from "@/components/FormDesigner/FormFields/RatingFile.vue";
import DividerComp from "@/components/FormDesigner/FormFields/DividerComp.vue";
import FlipSwitch from "@/components/FormDesigner/FormFields/FlipSwitch.vue";

export default {
  props: {
    work_Proof: Boolean,
    userObject: Object,
  },
  components: {
    TextField,
    // NumberField,
    CheckboxField,
    TextAreaField,
    // DateField,
    // DropdowmField,
    // TimePicker,
    RatingFile,
    DividerComp,
    FlipSwitch,
  },
  data: () => ({
    worktype: "",
    modelfree: 0,
    twomultipleimage: 0,
    Singlesingleimage: 0,
    Singlemultipleimage: 0,
    twosingleimage: 0,
    comment_work: "",
    images: [],
    form_template: [],
    new_array: [],
    formActionType: "previewproof",
  }),
  watch: {
    work_Proof: {
      handler() {
        if (this.work_Proof == true) {
          // console.log(this.userObject, "userObject");

          this.images = this.userObject.forms_details;
          // console.log(this.images);
          for (let i = 0; i < this.images.length; i++) {
            if (this.images[i].is_free_text == false) {
              this.new_array = this.images[i].form_template;
              for (let m = 0; m < this.new_array.length; m++) {
                this.new_array[m].type = String(this.new_array[m].data_type);
                this.new_array[m].displayLabel =
                  this.new_array[m].display_label;
                if (this.new_array[m].type == "rating") {
                  this.new_array[m].value = Number(this.new_array[m].value);
                }
              }
            }
          }

          // console.log(this.images);
          // console.log(this.images[0].form_template, "last");
        }
      },
      immediate: true,
    },
  },

  methods: {
    // for(var j=0;j<= this.images[i].form_template.length;j++){
    //           this.images[i].form_template[i].type= this.images[i].form_template[i].data_type
    //         }
    close_dialog() {
      // console.log(this.userObject.forms, "userObject");
      this.$emit("clicked", 0);
    },
  },
};
</script>
  
  <style>
</style>