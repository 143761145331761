<template>
  <div>
    <v-dialog v-model="geoproof" persistent width="500px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >Proof of Work Swipes - {{ userObject.user_name }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-card class="">
          <GmapMap
            :center="
              swipeData[0] != undefined ? swipeData[0].position : defaultCenter
            "
            :zoom="10"
            map-type-id="terrain"
            style="width: 100%; height: 400px"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in swipeData"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="center = m.position"
              :icon="{ url: m.marker }"
            />
            <!-- :title="' swipped at location : ' + m.location" -->
          </GmapMap>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  props: {
    geoproof: Boolean,
    userObject: Object,
  },

  data: () => ({
    defaultCenter: {
      lng: 0,
      lat: 0,
    },
    swipeData: [],
    geoSwipData: [],

    memberSwipeItems: [],
  }),
  watch: {
    geoproof: {
      handler() {
        // console.log(this.userObject.geo_lat);
        this.swipeData = [];
        this.geoSwipData = [];
        let baseImage = "https://maps.google.com/mapfiles/ms/icons/";
        let markerImage = [
          "red-dot.png",
          "yellow-dot.png",
          "green-dot.png",
          "blue-dot.png",
          "orange-dot.png",
          "purple-dot.png",
          "pink-dot.png",
        ];
        for (let i = 0; i < this.userObject.geo_lat.length; i++) {
          this.geoSwipData.push({
            //   location: this.userDetailsObj.box_location,
            position: {
              lat: Number(this.userObject.geo_lat[i].user_lat),
              lng: Number(this.userObject.geo_lat[i].user_long),
            },
            marker:
              baseImage +
              markerImage[Math.floor(Math.random() * markerImage.length)],
          });
          this.swipeData = this.geoSwipData;
        }
        // console.log(this.swipeData);
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
    //   console.log(this.swipeData.length, "333");
      this.geoSwipData = [];
    //   console.log(this.swipeData.length, "after");

      this.$emit("clicked", 0);
    },
  },
};
</script>
  
  <style></style>
  