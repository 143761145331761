<template>
  <div>
    <v-card flat class="mt-5">
      <v-row no-gutters>
        <v-col
          v-if="$vuetify.breakpoint.name != 'xs'"
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xl="12"
        >
          <v-toolbar dense elevation="0">
            <v-dialog
              ref="dialog1"
              v-model="modal"
              color="#f4813f"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  outlined
                  class="mt-6"
                  persistent-hint
                  :disabled="isLoading == true"
                  label="Select Date"
                  readonly
                  color="#f4813f"
                  append-icon="mdi-calendar"
                  style="max-width: 150px"
                  @click:append="date ? getcal() : getcal()"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="minmonth"
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  v-on:click="filter_data()"
                  text
                  color="#f4813f"
                  @click="$refs.dialog1.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- <v-dialog
            ref="dialog"
            v-model="endmodal"
            color="#f4813f"
            :return-value.sync="todate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataFormatted"
                dense
                outlined
                class="mt-6 ml-2"
                persistent-hint
                label="To Date"
                color="#f4813f"
                append-icon="mdi-calendar"
                style="max-width: 150px"
                @click:append="todate ? gettimecal() : gettimecal()"
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              :max="new Date().toISOString().substr(0, 10)"
              :min="date"
              v-model="todate"
              color="#f4813f"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="#f4813f" @click="endmodal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="#f4813f"
                v-on:click="filter_data()"
                @click="$refs.dialog.save(todate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog> -->
            <v-text-field
              v-model="search"
              label="Search"
              dense
              append-icon="mdi-magnify"
              style="max-width: 220px"
              class="mt-6 ml-2"
            ></v-text-field>
            <v-spacer />
          </v-toolbar>
        </v-col>

        <v-col cols="12" md="4" v-if="$vuetify.breakpoint.name == 'xs'">
          <v-toolbar dense elevation="0">
            <v-text-field
              v-model="search"
              label="Search"
              dense
              append-icon="mdi-magnify"
              class="mt-6 mr-2"
            ></v-text-field>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :height="height"
          :headers="PresentData"
          :items="PresentItems"
          dense
          :search="search"
          :options.sync="pagination"
          @update:options="get_audit_logs_Pagination()"
          :loading="isLoading"
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
          :sort-by="['user_name']"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text"
              >No Data available.
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.forms_details`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon
                    dense
                    v-on="on"
                    depressed
                    color="primary"
                    medium
                    @click="proof_ofprezence(item)"
                    class="ml-4"
                    :disabled="item.forms_details == null"
                  >
                    mdi-information-slab-circle-outline
                  </v-icon>
                </div>
              </template>

              <span
                v-if="
                  item.forms_details != undefined && item.forms_details != null
                "
                class="white--text"
              >
                Proof of Work</span
              >
              <span v-else class="white--text">No Forms</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="view_swipes_for_the_day(item)"
                  color="primary"
                  v-if="item"
                  small
                  v-on="on"
                  >mdi-map-marker</v-icon
                >
              </template>
              <span class="white--text">View Geo</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
    <div v-if="componentcheck == 1">
      <ProofworkView
        :work_Proof="work_Proof"
        @clicked="work_Proof = false"
        :userObject="userObject"
      />
    </div>
    <div v-if="componentcheck == 2">
      <ProofWorkgeo
        :geoproof="geoproof"
        :userObject="userObject"
        @clicked="geoproof = false"
      />
    </div>
  </div>
</template>
  
  <script>
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { proof_of_work_report } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import ProofWorkgeo from "@/components/Dialogs/ProofWorkgeo.vue";
import ProofworkView from "@/components/Dialogs/ProofworkView.vue";

export default {
  components: {
    Overlay,
    ProofworkView,
    ProofWorkgeo,
  },
  data: () => ({
    fixed: true,
    modal: false,
    work_Proof: false,
    endmodal: false,
    geoproof: false,
    userObject: {},
    componentcheck: 0,
    pagination: {
      itemsPerPage: 10,
      page: 1,
    },
    search: "",
    next_token: null,
    isLoading: false,

    PresentData: [
      { text: "Member Id", value: "employee_id", sortable: false },
      { text: "Member Name", value: "user_name", sortable: false },
      { text: "Forms Count", value: "forms_count" },
      { text: "Proof Of Work", value: "forms_details", sortable: false },
      { text: "Geo", value: "Actions", sortable: false },
    ],
    PresentItems: [],

    overlay: false,
    date: new Date().toISOString().substr(0, 10),
    todate: new Date().toISOString().substr(0, 10),
    minmonth: "",
    selectedDate: "",
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    dataFormatted() {
      return this.dateformating(this.todate);
    },
  },
  watch: {},
  async created() {
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );

    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    await this.get_daily_present_reports();
    this.height = window.innerHeight - 210;
  },
  methods: {
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    view_swipes_for_the_day(item) {
      this.componentcheck = 2;
      this.userObject = item;
      this.geoproof = true;
    },
    proof_ofprezence(item) {
      this.componentcheck = 1;
      this.work_Proof = true;
      this.userObject = item;
      // console.log(item);
    },
    filter_data() {
      this.next_token = null;
      this.PresentItems = [];
      this.get_daily_present_reports();
    },
    get_audit_logs_Pagination() {
      if (this.next_token) {
        this.get_daily_present_reports();
      }
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    getcal() {
      this.modal = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    gettimecal() {
      this.endmodal = true;
    },
    dateformating(todate) {
      if (!todate) return null;
      const [year, month, day] = todate.split("-");
      return `${day}/${month}/${year}`;
    },
    async get_daily_present_reports() {
      this.isLoading = true;
      this.overlay = true;

      try {
        let result = await API.graphql(
          graphqlOperation(proof_of_work_report, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,

              nextToken: this.next_token,
              limit: 100,
              start_time: new Date(this.date).getTime() / 1000,
              end_time: new Date(this.todate).getTime() / 1000,
            },
          })
        );
        this.isLoading = false;
        this.overlay = false;
        var checkvalue = JSON.parse(result.data.proof_of_work_report);
        if (checkvalue.Status == "ERROR") {
          this.isLoading = false;
          this.overlay = false;
          this.PresentItems = [];
          // console.log(checkvalue);
        } else {
          var response = JSON.parse(result.data.proof_of_work_report).data;
          let array = this.PresentItems.concat(response);
          this.PresentItems = array;
          const uniqueArrayOfObjects = this.PresentItems.filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.user_id === obj.user_id)
          );
          this.PresentItems = uniqueArrayOfObjects;
          this.next_token = JSON.parse(
            result.data.proof_of_work_report
          ).nextToken;
        }
        this.isLoading = false;
        this.overlay = false;
        // console.log(this.PresentItems);
      } catch (err) {
        console.log("error", err);
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });

        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>
  
  <style>
</style>
  